//@media (max-width: 1440px) {
//  .main{
//    background: url(../../assets/bg.png) no-repeat top right;
//    background-size: contain;
//    @apply bg-[length:auto_1100px];
//  }
//}

.main {
  position: relative;
  background: url(../../assets/bgNew.png) no-repeat top center;
  background-size: auto 900px, contain;
  //@apply xl:bg-[length:auto_900px] 2xl:bg-[length:auto_960px];
  &:after {
    content: "";
    z-index: 1;
    display: block;
    position: absolute;
    left: 0;
    top: 300px;
    width: 100%;
    background: linear-gradient(to top, rgb(58, 60, 66) 0%, rgb(58 60 66 / 85%) 25%, rgba(0, 212, 255, 0) 100%);
    height: 660px;
    //@apply lg:h-h600;
  }

  @media (max-width: 1440px) {
    background: url(../../assets/bg.png) no-repeat top right;
    background-size: auto 850px, contain;
    //@apply bg-[length:auto_1100px];
  }
  @media (max-width: 1030px) {
    background: none;
    &:after {
      content: "";
      z-index: 1;
      display: block;
      position: absolute;
      left: 0;
      top: 100px;
      width: 100%;
      background: linear-gradient(to top, rgb(58, 60, 66) 0%, rgb(58 60 66 / 35%)25%, rgba(0, 212, 255, 0) 100%);
      height: 660px;
      //@apply h-h600;
    }
  }

  @media (max-width: 770px) {
    &:after {
      content: "";
      z-index: 1;
      display: block;
      position: absolute;
      left: 0;
      top: 15px;
      width: 100%;
      height: 460px;
      background: linear-gradient(to top, rgb(58, 60, 66) 0%, rgb(58 60 66 / 55%) 25%, rgba(0, 212, 255, 0) 100%);
      //@apply h-h460;
    }
  }
  @media (max-width: 640px) {
    &:after {
      content: "";
      z-index: 1;
      display: block;
      position: absolute;
      left: 0;
      top: 40px;
      width: 100%;
      height: 345px;
      background: linear-gradient(to top, rgb(58, 60, 66) 0%, rgb(58 60 66 / 55%) 25%, rgba(0, 212, 255, 0) 100%);
      //@apply h-[345px];
    }
  }
  @media (max-width: 525px) {
    &:after {
      content: "";
      z-index: 1;
      display: block;
      position: absolute;
      left: 0;
      top: 96px;
      width: 100%;
      height: 285px;
      background: linear-gradient(to top, rgb(58, 60, 66) 0%, rgb(58 60 66 / 65%) 20%, rgba(0, 212, 255, 0) 100%);
      //@apply h-[285px];
    }
  }

  @media (max-width: 435px) {
    &:after {
      content: "";
      z-index: 1;
      display: block;
      position: absolute;
      left: 0;
      top: 92px;
      width: 100%;
      height: 275px;
      background: linear-gradient(to top, rgb(58, 60, 66) 0%, rgb(58 60 66 / 65%) 15%, rgba(0, 212, 255, 0) 100%);
      //@apply h-[275px];
    }
  }

  @media (max-width: 395px) {
    &:after {
      content: "";
      z-index: 1;
      display: block;
      position: absolute;
      left: 0;
      top: 96px;
      width: 100%;
      height: 270px;
      background: linear-gradient(to top, rgb(58, 60, 66) 0%, rgb(58 60 66 / 65%) 15%, rgba(0, 212, 255, 0) 100%);
      //@apply h-[270px];
    }
  }

  @media (max-width: 260px) {
    &:after {
      content: "";
      z-index: 1;
      display: block;
      position: absolute;
      left: 0;
      top: 116px;
      width: 100%;
      height: 245px;
      background: linear-gradient(to top, rgb(58, 60, 66) 0%, rgb(58 60 66 / 65%) 15%, rgba(0, 212, 255, 0) 100%);
      //@apply h-[245px];
    }
  }
}

.woman_img{
  @media (min-width: 1031px) {
    @apply hidden;
  }
  @media (max-width: 1050px) {
    @apply w-[420px] translate-y-[10rem] translate-x-2;;
  }
  @media (max-width: 770px) {
    @apply w-[250px] translate-y-[7rem] -translate-x-5;
  }
  @media (max-width: 640px) {
    @apply w-[200px] translate-y-[6rem] -translate-x-7;
  }
  @media (max-width: 525px) {
    @apply w-[200px] translate-y-[6rem] translate-x-2;
  }
  @media (max-width: 435px) {
    @apply w-[190px] translate-y-[6rem] translate-x-2;
  }
  @media (max-width: 395px) {
    @apply w-[190px] translate-y-[6rem] translate-x-3;
  }
  @media (max-width: 260px) {
    @apply w-[180px] translate-y-[6rem] translate-x-3;
  }
}

.swiper {
 @media (min-width: 640px) {
   min-height: 20rem;
 }
 @media (min-width: 768px) {
   min-height: 27rem;
 }
 min-height: 17rem;

 .swiper-pagination {

   @apply flex items-end justify-center mt-9 md:mt-7 relative;

   .swiper-pagination-bullet {
     @apply w-3 h-3 md:w-5 md:h-5 bg-transparent border-4 md:border-4 border-orange rounded-full opacity-100;
   }

   .swiper-pagination-bullet-active{
     @apply bg-orange;
   }
 }
}

.title{
  @apply leading-8;
}

