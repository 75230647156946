@font-face {
    font-family: 'Monsterrat';
    src: url('assets/fonts/Montserrat/Montserrat-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('assets/fonts/Montserrat/Montserrat-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('assets/fonts/Montserrat/Montserrat-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Montserrat';
    src: url('assets/fonts/Montserrat/Montserrat-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Montserrat';
    src: url('assets/fonts/Montserrat/Montserrat-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Montserrat';
    src: url('assets/fonts/Montserrat/Montserrat-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'Montserrat';
    src: url('assets/fonts/Montserrat/Montserrat-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Liquido';
    src: url('assets/fonts/Liquido/Liquido-Fluid.otf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Maltha';
    src: url('assets/fonts/Maltha/Maltha-Regular.otf') format('truetype');
    font-weight: 400;
    font-style: normal;
}



@tailwind base;
@tailwind components;
@tailwind utilities;

body, *{
    margin: 0;
}

.montserrat{
    font-family: "Montserrat", sans-serif;
}

.liquido{
    font-family: "Liquido", sans-serif;
}
.maltha{
    font-family: "Maltha", sans-serif;
}
.App {
    @media (max-width: 6000px) {
        overflow-x: hidden;
    }
}
